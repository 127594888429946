// Home.js
import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { CurrentDashboard } from './CurrentDashboard';
import { ForecastDashBoard } from './ForecastDashBoard';

function Home() {

  // Fetch and set the current and forecast data
  const [currentData, setCurrentData] = useState(null);
  const [forecastData, setForecastData] = useState(null);
  useEffect(() => {
    const VERSION = Date.now();
    d3.json(`https://carticasiweatherdata.blob.core.windows.net/public/current.json?version=${VERSION}`).then(data => {
      setCurrentData(data.observations[0]);
    });
    d3.json(`https://carticasiweatherdata.blob.core.windows.net/public/forecast.json?version=${VERSION}`).then(data => {
      setForecastData(data);
    });
  }, []);

  return (
    <div>
      {currentData && <CurrentDashboard data={currentData} />}
      {forecastData && <ForecastDashBoard data={forecastData} />}
    </div>
  );
}

export default Home;
