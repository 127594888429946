// HistoryPage.js
import React from 'react';

function HistoryPage() {
  return (
    <div>
      <h2>History Charts</h2>
      {/* Content for history charts */}
    </div>
  );
}

export default HistoryPage;
